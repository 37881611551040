<!-- page-wrapper Start-->
<div class="container-fluid p-0">
    <!-- login page start-->
    <div class="authentication-main">
        <div class="row">
            <div class="col-md-12">
                <div class="auth-innerright">
                    <div class="authentication-box">
                        <div>
                            <div class="card-body p-0">
                                <div class="cont text-center b-light" [ngClass]="{'s--signup': signup}">
                                    <div>
                                        <div class="theme-form">
                                            <h4>DzTaxi Dashboard</h4>
                                            <div class="form-group">
                                                <label class="col-form-label pt-0">Email</label>
                                                <input class="form-control" type="text" required="" [(ngModel)]="email">
                                                <div class="text text-danger mt-1" *ngIf="errors.login_required">
                                                    email обязательный
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-form-label">Password</label>
                                                <input class="form-control" type="password" required="" [(ngModel)]="password">
                                                <div class="text text-danger mt-1" *ngIf="errors.password_required">
                                                    Пароль обязательный
                                                </div>
                                            </div>
                                            <div class="form-group form-row mt-3 mb-0">
                                                <button class="btn btn-primary btn-block" (click)="signin()"><span>Sign in</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sub-cont">
                                        <img src="assets/header.jpg" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- login page end-->
</div>