<!-- Page Sidebar Start-->
<div class="logo-wrapper">
    <div class="header_top">
        <img class="img-fluid" src="assets/header.jpg" alt="">
        <div class="header_top_name">
            <h4>DzTaxi</h4>
            <h6>Dashboard</h6>
        </div>
    </div>
    <!-- <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="">
    <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt=""> -->
    <div class="back-btn" (click)="sidebarToggle()">
        <i class="fa fa-angle-left"></i>
    </div>
    <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'grid'" class="status_toggle middle"></app-feather-icons>
    </div>
</div>
<div class="logo-icon-wrapper">
    <a href="javascript:void(0)">
        <img class="img-fluid" src="assets/header.jpg" alt="">
        <!-- <img class="img-fluid" src="assets/images/logo/logo-icon.png" alt=""> -->
    </a>
</div>
<nav>
    <div class="main-navbar">
        <div class="left-arrow" id="left-arrow" [class.d-none]="leftArrowNone" (click)="scrollToLeft()">
            <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
        </div>
        <div id="mainnav"
            [ngStyle]="{ marginLeft : this.layout.config.settings.sidebar.type == 'horizontal-wrapper' ? margin + 'px' : '0px'}">
            <ul class="nav-menu custom-scrollbar">
                <li class="back-btn">
                    <a href="javascript:void(0)">
                        <img class="img-fluid" src="assets/header.jpg" alt="">
                        <!-- <img class="img-fluid" src="assets/images/logo/logo-icon.png" alt=""> -->
                    </a>
                    <div class="mobile-back text-right" (click)="sidebarToggle()">
                        <span>Back</span>
                        <i class="fa fa-angle-right pl-2" aria-hidden="true"></i>
                    </div>
                </li>
                <li class="dropdown" *ngFor="let menuItem of menuItems" [ngClass]="{active: menuItem.active}">
                    <div class="sidebar-title" *ngIf="menuItem.headTitle1">
                        <div>
                            <h6 class="lan-1">{{menuItem.headTitle1 | translate}}</h6>
                            <p class="lan-2">{{menuItem.headTitle2 | translate}}</p>
                        </div>
                    </div>
                    <!-- Sub -->
                    <a href="javascript:void(0)" class="nav-link menu-title" [ngClass]="{active: menuItem.active}"
                        *ngIf="menuItem.type === 'sub'" (click)="toggletNavActive(menuItem)">
                        <app-feather-icons [icon]="menuItem.icon"></app-feather-icons><span>{{menuItem.title |
                            translate}}</span>
                        <label class="badge badge-{{menuItem.badgeType}}"
                            *ngIf="menuItem.badgeType && menuItem.badgeValue">{{menuItem.badgeValue}}</label>
                        <div class="according-menu">
                            <i class="fa fa-angle-{{menuItem.active ? 'down' : 'right'}} pull-right"
                                *ngIf="menuItem.children"></i>
                        </div>
                    </a>
                    <!-- Link -->
                    <a [routerLink]="!menuItem.type ? null : [menuItem.path]" routerLinkActive="active"
                        class="nav-link menu-title" [ngClass]="{active: menuItem.active}"
                        *ngIf="menuItem.type === 'link'">
                        <app-feather-icons [icon]="menuItem.icon"></app-feather-icons><span>{{menuItem.title |
                            translate}}</span>
                        <label class="badge badge-{{menuItem.badgeType}}"
                            *ngIf="menuItem.badgeType && menuItem.badgeValue">{{menuItem.badgeValue}}</label>
                        <div class="according-menu">
                            <i class="fa fa-angle-{{menuItem.active ? 'down' : 'right'}} pull-right"
                                *ngIf="menuItem.children"></i>
                        </div>
                    </a>
                    <!-- External Link -->
                    <a href="{{ !menuItem.type ? null : menuItem.path }}" class="nav-link menu-title"
                        [ngClass]="{active: menuItem.active}" *ngIf="menuItem.type === 'extLink'">
                        <app-feather-icons [icon]="menuItem.icon"></app-feather-icons><span>{{menuItem.title |
                            translate}}</span>
                        <label class="badge badge-{{menuItem.badgeType}}"
                            *ngIf="menuItem.badgeType && menuItem.badgeValue">{{menuItem.badgeValue}}</label>
                        <div class="according-menu">
                            <i class="fa fa-angle-{{menuItem.active ? 'down' : 'right'}} pull-right"
                                *ngIf="menuItem.children"></i>
                        </div>
                    </a>
                    <!-- External Tab Link -->
                    <a href="{{ !menuItem.type ? null : menuItem.path }}" target="_blank" class="nav-link menu-title"
                        [ngClass]="{active: menuItem.active}" *ngIf="menuItem.type === 'extTabLink'">
                        <app-feather-icons [icon]="menuItem.icon"></app-feather-icons><span>{{menuItem.title }}</span>
                        <label class="badge badge-{{menuItem.badgeType}}"
                            *ngIf="menuItem.badgeType && menuItem.badgeValue">{{menuItem.badgeValue}}</label>
                        <div class="according-menu">
                            <i class="fa fa-angle-{{menuItem.active ? 'down' : 'right'}} pull-right"
                                *ngIf="menuItem.children"></i>
                        </div>
                    </a>
                    <!-- 2nd Level Menu -->
                    <ul class="nav-submenu menu-content" [ngClass]="{active: menuItem.active}"
                        [ngClass]="{'menu-open': menuItem.active, 'menu-close': !menuItem.active }"
                        *ngIf="menuItem.children" [style.display]="menuItem.active ? 'block' : 'none'">
                        <li *ngFor="let childrenItem of menuItem.children" [ngClass]="{active: childrenItem.active}">
                            <!-- Sub -->
                            <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'"
                                (click)="toggletNavActive(childrenItem)">
                                <span> {{childrenItem.title | translate}}</span>
                                <div class="according-menu">
                                    <i class="fa fa-angle-{{childrenItem.active ? 'down' : 'right'}} pull-right"
                                        *ngIf="childrenItem.children"></i>
                                </div>
                            </a>
                            <!-- Link -->
                            <a [routerLink]="!childrenItem.type ? null : [childrenItem.path]"
                                *ngIf="childrenItem.type === 'link'" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">
                                <span>{{childrenItem.title | translate}}</span>
                                <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
                            </a>
                            <!-- External Link -->
                            <a href="{{ !childrenItem.type ? null : childrenItem.path }}"
                                *ngIf="childrenItem.type === 'extLink'" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">
                                <span>{{childrenItem.title | translate}}</span>
                                <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
                            </a>
                            <!-- External Tab Link -->
                            <a href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank"
                                *ngIf="childrenItem.type === 'extTabLink'">
                                <span>{{childrenItem.title | translate}}</span>
                                <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
                            </a>
                            <!-- 3rd Level Menu -->
                            <ul class="nav-sub-childmenu submenu-content" *ngIf="childrenItem.children"
                                [ngClass]="{active: childrenItem.active}"
                                [ngClass]="{'menu-open': childrenItem.active, 'menu-close': !childrenItem.active }"
                                [style.display]="childrenItem.active ? 'block' : 'none'">
                                <li *ngFor="let childrenSubItem of childrenItem.children"
                                    [ngClass]="{active: childrenSubItem.active}">
                                    <!-- Link -->
                                    <a [routerLink]="!childrenSubItem.type ? null : [childrenSubItem.path]"
                                        *ngIf="childrenSubItem.type === 'link'" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">
                                        <span> {{childrenSubItem.title | translate}}</span>
                                    </a>
                                    <!-- External Link -->
                                    <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}"
                                        *ngIf="childrenSubItem.type === 'extLink'" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">
                                        <span>{{childrenSubItem.title | translate}}</span>
                                    </a>
                                    <!-- External Tab Link -->
                                    <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}" target="_blank"
                                        *ngIf="childrenSubItem.type === 'extTabLink'">
                                        <span>{{childrenSubItem.title | translate}}</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="dropdown">
                    <a class="nav-link menu-title" (click)="logout()" style="cursor: pointer;">
                        <app-feather-icons icon="log-out"></app-feather-icons><span>Logout</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="right-arrow" id="right-arrow" [class.d-none]="rightArrowNone" (click)="scrollToRight()">
            <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
        </div>
    </div>
</nav>